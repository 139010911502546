.AccountDropContain {
    display: flex;
    gap: 5px;
    align-items: center;
    background-color: #f5f5f5;
    justify-content: space-between;
    padding: 5px 7px;
    border-radius: 5px;
}
.AccountCount{
    width: 40%;
}
.AccountCount h4,.AccountCount h4 span{
    font-size: 13px;
    margin: 0;
    color: #000;
}
.SelectContain{
    display: flex;
}
.AccountDropContain select{
    width: 200px;
    border: 1px solid #000;
    font-size: 13px;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid gray;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    outline: none;
}
.AccountAddBtn{
    width: 40px;
    padding: 3px 4px;
    border: none;
    background-color: #fff;
    color: #000;
    border-radius: 5px;
    border: 1px solid gray;
    margin-left: 5px;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}
.AccountAddBtn img{
    width: 14px;
}
.AccountLinkCard{
    background-color: #fff;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.SureBtn{
    background-color: rgb(5, 131, 153);
    color: #fff;
    padding: 5px 15px;
    border: none;
    border-radius: 5px;
}
.CancleBtn{
    background-color: rgb(204, 7, 7);
    color: #fff;
    padding: 5px 15px;
    border: none;
    border-radius: 5px;
}
.TrackDetails.AccountLink h4{
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 0;

}
.TrackDetails.AccountLink h4 span{
    font-weight: 400;
}
.AccountBtnContain{
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 10px;
    position: relative;
}
.AddAccountModal{
    position: fixed;
    background-color: #fff;
    max-width: 250px;
    right: -100%;
    width: 100%;
    height: auto;
    z-index: 101;
    padding: 20px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: .4s;
}
.AddAccountModal.Active{
    right:20px;
}
.AddAccountModal .AccountGroup{
    margin-bottom: 10px;
}
.AddAccountModal .AccountGroup:last-child{
    margin-bottom: 0;
}
.AddAccountModal .AccountGroup label{
    display: block;
    font-weight: bold;
    margin-bottom: 3px;
}
.AddAccountModal .AccountGroup select,.AddAccountModal .AccountGroup input{
    font-size: 12px;
    outline: none;
    padding: 5px 10px;
    border: 1px solid #000;
    border-radius: 5px;
    width: 100%;
}
.AddAccountModal .AccountGroup input::placeholder{
    color: #000;
}
.AccountGroup:has( > button){
    text-align: center;
}
.AccountGroup button{
    border: none;
    width: 100px;
    padding: 10px 15px;
    border-radius: 5px;
}
.AccountGroup button.add{
    background-color: rgb(8, 160, 187);
    color: #fff;
}
.AccountGroup button.delete{
    background-color: rgb(190, 12, 12);
    color: #fff;
}
.AccountGroup button:first-child{
    margin-right: 10px;
}

.AccountLinkCardContain{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
    padding-top: 10px ;
}
.AccountLinkCardContain .TrackDetails{
    max-width: 400px;
}
@media(max-width:1260px){
    .AccountLinkCardContain{
        justify-content: center;
    }
}
@media(max-width:385px){
    .AccountDropContain select{
        width: 120px;
    }
}
@media(max-width:303px){
    .AccountDropContain{
        flex-wrap: wrap;
    }
    .AccountCount{
        width: 100%;
    }
}
