.GraphContainer{
    position: relative;
    min-width: 200px;
    width: 100%;
    max-width: 400px;
}
.ChartLegend {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: flex-start; /* Align items to the left */
    margin-top: 10px; /* Space between chart and legend */
}
.GraphTitle{
    background-color: #f5f5f5;
    padding: 10px;
    color: #000;
    font-size: 14px;
    text-align: center;
    margin-bottom: 20px;
}
