.HelpSupportContain .accordion {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.AccordianContain{
    /* padding: 10px; */
    /* padding-right: 0; */
}
.HelpSupportContain .accordion-item {
    margin-bottom: 3px;
}
.HelpSupportContain .accordion-item:last-child{
    margin-bottom: 0;
}
.HelpSupportContain .accordion-header {
    padding: 10px;
    cursor: pointer;
    background: gray;
    color: #fff;
    transition:  0.3s;
    position: relative;

}
.HelpSupportContain .accordion-header img{
    width: 14px;
    filter: invert();
    position: absolute;
    right: 7px;
    top: 14px;
}
.HelpSupportContain .accordion-header .PlusIcon{
    display: block;
}
.HelpSupportContain .accordion-header .MinusIcon{
    display: none;
}
.HelpSupportContain .accordion-header:has(+ .accordion-body) .MinusIcon{
    display: block;
}
.HelpSupportContain .accordion-header:has(+ .accordion-body) .PlusIcon{
    display: none;
}
.HelpSupportContain .accordion-body .accordion-header,.HelpSupportContain .accordion-body .accordion-header:hover{
background-color: rgb(185, 180, 180);
}
.HelpSupportContain .accordion-body .accordion-body .accordion-header,.HelpSupportContain .accordion-body .accordion-body .accordion-header:hover{
    background-color: rgb(226, 223, 223);
    color: #000;
}
.HelpSupportContain .accordion-body .accordion-body .accordion-header,.HelpSupportContain .accordion-body .accordion-body .accordion-header img{
    filter: none;
}
.HelpSupportContain .accordion-header:hover {
    background: gray;
}

.HelpSupportContain .accordion-body {
    padding: 0;
    transition: .4s;
}
.AccordianContent{
    padding: 20px 10px;
    text-align: center;
    transition: .4s;
}
.AccordianContent textarea{
    width: 100%;
    border: 1px solid gray;
    font-size: 13px;
    padding: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 5px;
    background-color: #fcf3f3;
}
.SubmitButton{
    background-color: rgb(18, 184, 235);
    color: #fff;
    border-radius: 5px;
    padding: 10px 15px;
    border: none;
    outline: none;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    width: 100%;
    max-width: 150px;
    margin: auto;
    margin-top: 10px;
}

/* modal css start */
.SubmitModalContain{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1010;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #00000063;
}
.SubmitModal{
    background-color: #fff;
    border-radius: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    padding: 20px;

}

.SubmitModal .SubmitIConCheck{
    font-size: 55px;
    color: green;
}
.SubmitModal p{
    color: #000;
    text-align: center;
    margin-bottom: 0;
}
.TicketNo{
    font-weight: bold;
}
.SubmitModal button{
    background-color: orangered;
    color: #fff;
    font-size: 13px;
    border: none;
    border-radius: 10px;
    width: 120px;
    padding: 10px;
}
/* modal css end */