#Header.Support{
    padding: 20px;
}
.HelpSupportContain{
    padding: 10px;
    margin-top: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.HelpSupportContain .accordion{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.HelpSupportContain .accordion-button:not(.collapsed),.HelpSupportContain .accordion-button.collapsed{
    background-color: gray;
    color: #fff;
}
.HelpSupportContain .accordion-button:focus{
    box-shadow: none;
}
.HelpSupportContain .accordion-button:not(.collapsed)::after{
    background-image: var(--bs-accordion-btn-icon);
}
.HelpSupportContain .accordion-button::after{
    filter: invert();
}
.HelpSupportContain .accordion-button:not(.collapsed){
box-shadow: none;
}


.Pcontent_card input,.Pcontent_card input::placeholder{
    color: #000;
    font-size: 13px;
}
.Pcontent_card input{
    padding: 5px 10px;
    width: 100%;
    border: 1px solid gray;
    border-radius: 5px;
    outline: none;
}
.Pcontent_card:has(.PasswordChangebtn){
    text-align: center;
}
.PasswordChangebtn{
    background-color: rgb(18, 184, 235);
    color: #fff;
    border-radius: 5px;
    padding: 10px 15px;
    border: none;
    outline: none;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    width: 150px;
    margin: auto;
}
.PinputContain{
    position: relative;
}
.ShowPasswordbtn{
    border: none;
    position: absolute;
    right: 10px;
    bottom: 6px;
    background: transparent;
}
.ShowPasswordbtn img{
    width: 20px;
}
.HelpSupportHeader{
    padding: 20px 0;
    padding-top: 0;
}
.HelpSupportHeader h4{
    color: #000;
    font-size: 14px;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 15px;
}
.HelpSupportHeader h4 img{
    width: 25px;
    margin-right: 5px;
}
.HelpSupportDCard{
    padding: 10px;
    border: 1px solid #f5f5f5;
    border-radius: 5px;
    box-shadow: rgba(22, 198, 230, 0.76) 0px 5px, rgba(113, 225, 253, 0.3) 0px 10px, rgba(173, 222, 241, 0.2) 0px 15px;
}
.HelpSupportDCard.resolve{
    box-shadow: rgba(22, 230, 67, 0.76) 0px 5px, rgba(113, 253, 136, 0.3) 0px 10px, rgba(179, 241, 173, 0.2) 0px 15px;
}
.HelpSupportDCard.pending{
    box-shadow: rgba(230, 36, 22, 0.76) 0px 5px, rgba(253, 113, 113, 0.3) 0px 10px, rgba(241, 175, 173, 0.2) 0px 15px;
}
.HelpSupportDCard .TicketCount{
    text-align: center;
    display: block;
    font-size: 18px;
    letter-spacing: 2px;
    color: #0993bd;
}
.HelpSupportDCard.pending .TicketCount{
    color: red;
}
.HelpSupportDCard.resolve .TicketCount{
    color: green;
}
.HelpSupportDCard h4{
    color: #000;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    margin-top: 5px;
}

@media(max-width:250px){
    .HelpSupportDCard{
        padding: 10px 0;
    }
    .TrackCard{
        flex-wrap: wrap;
    }
}