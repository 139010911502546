.employee-list {
    list-style: none;
    padding: 0;
    margin: 0;
}
.Chatprofile{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
}
.Chatprofile img{
    width: 100%;
    object-fit: cover;
}
.employee-item {
    display: flex; /* Use flexbox for alignment */
    align-items: center; /* Center items vertically */
    padding: 15px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    transition: background-color 0.3s;
}
.employee-item > div.MuiAvatar-root {
    background-color: orangered;
}
.employee-item:hover {
    background-color: #f0f0f0;
}

.employee-info {
    display: flex;
    flex-direction: column; /* Stack name and last message vertically */
    margin-left: 15px; /* Space between avatar and text */
}

.employee-name {
    font-weight: bold;
    font-size: 1.1em;
}

.employee-last-message {
    display: -webkit-box; /* Create a flexible box */
    -webkit-box-orient: vertical; /* Set box orientation */
    overflow: hidden; /* Hide overflow content */
    -webkit-line-clamp: 1; /* Limit to 1 line */
    line-clamp: 1; /* Limit to 1 line */
    max-height: 2.2em; /* Set max height based on line height */
    font-size: 0.9em; /* Adjust font size if needed */
    color: #666; /* Optional: Change text color for last message */
}

.ContentContainer:has( > .search-container){
    padding-top: 0;
}
.search-container {
    position: sticky; /* Make the search container sticky */
    top: 0; /* Stick to the top */
    background-color: white; /* Set background to avoid overlap with other elements */
    z-index: 10; /* Ensure it appears above other content */
    margin: 0; /* Reset margin */
    padding-top: 20px;
    padding-bottom: 15px ;
}

.search-input {
    width: 100%;
    padding: 10px 40px;
    border: 1px solid #ccc;
    border-radius: 45px;
    font-size: 1em;
}

.search-icon {
    position: absolute;
    left: 15px;
    top: 55%;
    transform: translateY(-50%);
    color: orangered;
}

.search-input:focus {
    outline: none; /* Remove outline on focus */
    border-color: orangered; /* Change border color on focus */
}

.blocked-message{
    text-align: center;
}