.FollowUpHistory .TrackMessageCard h4{
    margin-bottom: 3px;
}
.FollowupHistoryIssue{
    padding-right: 25px;
}
.FollowUpHistory .TrackMessageCard span{
    font-size: 13px;
}
.FollowUpHistory .TrackMessageCard textarea{
    outline: none;
}
.FollowupHistoryIssue{
    color: orangered;
}
.RequestModalBtn.PlayVoiceBtn{
    border: none;
    background-color: orangered;
    color: #fff;
    font-size: 12px;
    padding: 10px 15px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    width: 115px;
    text-transform: uppercase;
    font-weight: 500;
}
.RequestModalBtn.PlayVoiceBtn svg{
    color: #fff;
}