.TrackHeader{
    background-color: #f5f5f5;
    color: #fff;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-radius: 5px;
    /* max-width: 400px; */
    width: 100%;
    margin: auto;
    margin-top: 5px;
}
.Trackselect{
    width: 150px;
}
.Trackselect select{
    font-size: 13px;
    font-weight: bold;
    width: 100%;
    padding: 5px 10px;
    border-radius: 5px;
    outline: none;
    color: orangered;
    border: 1px solid #000;
}
.Trackinput{
    position: relative;
    width: 100%;
}
.TrackMobile{
    width: 150px;
}
.Trackinput.TrackMobile input{
    font-size: 13px;
    font-weight: bold;
    color: orangered;
    padding: 4px 10px;
    border-radius: 5px;
    outline: none;
    border: 1px solid #000;
}
.Trackinput.TrackMobile input::placeholder{
    font-size: 13px;
    font-weight: bold;
    color: orangered;
}
.TrackTitle{
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: start;
}
.TrackTitle img{
    width: 16px;
    margin-right: 5px;
}

.Trackinput input{
    font-size: 13px;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    width: 100%;
    outline: none;
}
.Trackinput button{
    position: absolute;
    right: 10px;
    top: 4px;
    border: none;
    background: transparent;    
}
.Trackinput button img{
    width: 14px;
}
.TrackDetails{
    padding: 10px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    position: relative;
    overflow: hidden;
    width: 100%;
    /* max-width: 400px; */
    margin: auto;
    margin-top: 20px;
}

.TrackDetails .CardCount{
    position: absolute;
    top: 0px;
    right: 0;
    width: 35px;
    height: 31px;
    background-color: #ff4500;
    color: #fff;
    font-size: 12px;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 2px;
}
.TrackMessageCard h4{
    font-size: 13px;
    margin-bottom: 6px;
}
.TrackMessageCard p{
    font-size: 12px;
    margin-bottom: 0;
}
.MessageCard{
    /* padding: 10px;
    background-color: #fceeee; */
    margin-top: 20px;
    border-radius: 10px;
}
.MessageCard p{
    text-align: justify;
    letter-spacing: 0.8px;
    font-weight: 500;
    color: #515050;
}
.portalDrop,.portalDrop option{
    text-transform: uppercase;
}
.portalContain{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 20px;
}
.portalContain h4{
    margin: 0;
    letter-spacing: 1px;
}
@media(max-width:360px){
    .Trackselect,.TrackMobile{
        width: 120px;
    }
}
@media(max-width:330px){
    .portalContain h4,.TrackTitle{
        font-size: 13px;
        letter-spacing: 0;
    }
    .HelpSupportDCard h4{
        font-size: 12px;
    }
    .HelpSupportDCard .TicketCount{
        letter-spacing: 1px;
    }

}
@media(max-width:310px){
    .TrackHeader{
        gap: 5px;
    }
    .TrackTitle{
        font-size: 11px;
    }
    
    .HelpSupportDCard h4{
        font-size: 11px;
    }
    .HelpSupportDCard .TicketCount{
        font-size: 14px;
        letter-spacing: 0;
    }
}
@media(max-width:299px){
    .portalContain,.TrackHeader{
        flex-wrap: wrap;
    }
    .Trackselect, .TrackMobile{
        width: 100%;
    }
    .portalContain h4{
        width: 100%;
        margin-bottom: 10px;
    }
}