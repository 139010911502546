.SignUpContain{
    background-color: #fff;
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
}
.SignUpContain::before{
    content: '';
    position: fixed;
    bottom: -70px;
    left: -70px;
    width: 160px;
    height: 160px;
    border-radius: 50pc;
    background-color: orangered;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    z-index: 1;
}
.SignUpContain::after{
    content: '';
    position: fixed;
    top: -70px;
    right: -70px;
    width: 160px;
    height: 160px;
    border-radius: 50pc;
    background-color: orangered;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    z-index: 1;
}
.SignUpLogo{
    max-width: 500px;
    width: 100%;
    padding-left: 2px;
    margin: auto;
}
.SignUpLogo img{
    width: 60px;
}
.SignUpCard{
    max-width: 500px;
    width: 100%;
    margin-top: 40px;
    padding: 40px;
    position: relative;
    margin: auto;
}
.SignUpTitle{
    margin-bottom: 50px;
}
.SignUpTitle h4{
    font-size: 33px;
    color: orangered;
    text-align: center;
}
.SignUpTitle p{
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    color: gray;
    letter-spacing: 1px;
    margin-bottom: 5px;
}
.SignUpGroup{
    margin-bottom: 15px;
}
.SignUpGroup label,.SignUpGroup.CheckBox a{
    color: orangered;
    font-size: 13px;
    font-weight: 600;
    display: block;
    margin-bottom: 2px;
}
.SignUpGroup label{
    position: relative;
    display: inline-block;
}
.SignUpGroup input{
    width: 100%;
    font-size: 13px;
    outline: none;
    border: none;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.SignUpGroup input::placeholder{
    color: rgb(143, 142, 142);
}


.SignUpGroup button{
    border: none;
    background-color: orangered;
    color: #fff;
    padding: 15px 30px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    text-transform: uppercase;
}
.SignUpBtn{
    position: absolute;
    right: 0;
}
.SignUpFooter{
    background-color: #f5f5f5;
    color: #000;
    padding: 10px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;

}
.SignUpFooter p{
    margin: 0;
    font-size: 8px;
    font-weight: bold;
    letter-spacing: 1px;
    color: #000;
    text-align: center;
    text-transform: uppercase;
}
.SignUpFooter p span{
    color: orangered;
    font-size: 8px;
    font-weight: bold;
    letter-spacing: 1px;
}
.SignUpGroup .PasswrdInpt{
    position: relative;
}
.SignUpGroup .PasswrdInpt button{
    border: none;
    background: transparent;
    position: absolute;
    padding: 0;
    top: 10px;
    right: 10px;
}
.SignUpGroup .PasswrdInpt button img{
    width: 18px;
}
.SignUpGroup.CheckBox {
    margin-bottom: 0;
}
.SignUpDivider{
    width: 100%;
    font-size: 13px;
    font-weight: 500;
    position: relative;
    margin-top: 70px;
    text-align: center;
}
.SignUpDivider::before{
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
    background-color: rgb(201, 201, 201);
}
.SignUpDivider span{
    background-color: #fff;
    position: relative;
    z-index: 1;
    padding: 0 10px;
}
.SignUpGroup.signUp{
    text-align: center;
}
.SignUpGroup.signUp p{
    font-size: 13px;
}
.SignUpGroup.signUp a{
    color: orangered;
    font-size: 14px;
    font-weight: 500;
}
.ProcessGroup{
    position: relative;
}
.LoginGroup button.GetOtpBtn{
    position: absolute;
    right: 0;
    border-radius: 10px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: rgb(7, 159, 170);
    padding: 10px;
    height: 100%;
    font-size: 11px;
}
.LoginGroup{
    transition: .4s;
}
.LoginGroup.MobileOtpContain{
    height: 0px;
    overflow: hidden;
    opacity: 0;
}
@media(min-width:500px){
    .SignUpGroup button{
        border-radius: 10px;
    }
    .SignUpBtn{
        position: static;
        text-align: center;
    }
}