.FollowUpHeader{
    background-color: #f5f5f5;
    /* color: #fff; */
    padding: 15px ;
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
}
.FollowUpHeader h4{
    /* text-align: center; */
    font-size: 13px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
}
.FollowUpHeader h4 span{
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 1px;
    margin-left: 3px;
}
.ColorGreen{
    color: green;
    font-weight: bold;
}
.ColorRed{
    color: orangered;
    font-weight: bold;
}
.TrackMessageCard.Status select{
    border: 1px solid #817f7f;
    padding: 5px;
    border-radius: 5px;
    width: 150px;
    font-size: 13px;
}
.FallowCard textarea{
    width: 100%;
    padding: 5px 10px;
    font-size: 13px;
    border: 1px solid #000;
    border-radius: 5px;
    color: #000;
}

.FallowCard textarea::placeholder{
    color: #000;
}
.HistoryBtn{
    left: -10px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: gray;
}
.CallNowBtn{
    position: static;
    border-radius: 10px;
    width: 115px;
    background-color: green;
}
/* .CallNowBtn span{
    display: inline-block;
    background-color: green;
} */
.CallNowBtn img{
    width: 20px;
    filter: invert();
}
@media(max-width:370px){
     .FollowupBtnContain .RequestModalBtn{
        width: 75px;
    }
    .FollowupBtnContain .CallNowBtn{
        width: 115px;
    }
}
@media(max-width:346px){
    .FollowUpHeader{
        flex-wrap: wrap;
    }
}
@media(max-width:320px){
    .Flexwrp320{
        flex-wrap: wrap;
        gap: 5px;
    }
}
@media(max-width:310px){
    .FollowupBtnContain{
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: center;
        align-items: center;

    }
    .FollowupBtnContain .RequestModalBtn{
        position: static;
        border-radius: 10px;
        width: 115px;
    }
}