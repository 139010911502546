#Header{
    background-color: orangered;
    color: #fff;
    padding: 20px 10px 40px;
    height: 95px;
}
.HeaderTitle{
    margin: 0;
    font-size: 18px;
}
.HeaderContain{
    display: flex;
    align-items: center;
}
.ProfileIcon{
    background-color: #fff;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px ;
    height: 35px;
    margin-right: 10px;
    overflow: hidden;
}
.HeaderContain img{
    width: 25px;
}
.LogoutCard {
    list-style: none;
    width: 100px;
    height: 100px;
    position: fixed;
    right: 10px;
    top: 60px;
    background-color: #fff;
    color: #000;
    text-align: center;
    padding: 10px;
    margin: 0;
    border-radius: 10px;
    border: 2px solid #f79b79;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    z-index: 11;
}
.LogoutCard.RightL{
    left: 10px;
}
.LogoutCard li a{
    font-size: 13px;
    margin-bottom: 5px;
    padding: 5px 10px;
    transition: .6s;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
}
.LogoutCard li a:hover {
    color: #fff;
}
.LogoutCard li a::before{
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: rgb(180, 180, 180);
    color: #fff;
    transition: .4s;
    z-index: -1;
}
.LogoutCard li a:hover::before{
   height: 100%;
}
.LogoutCard li a img{
    width: 16px;
    margin-right: 5px;
}

.ProfileIcon img {
    width: 100%; /* Set the desired width */
    height: 100%; /* Maintain the aspect ratio */
    border-radius: 50%; /* Optional: to make it circular */
    object-fit: cover; /* Ensures the image covers the entire area */
}

/* header end */

/* online status change */
.typing-status.set_status{
    text-align: center;
    margin-top: -7px;
    color: #60ffd3;
    font-size: 13px;
    font-weight: 500;
}
.profile_text{
    justify-content: space-between;
    display: flex;
    align-items: flex-start;
}.header_icon_1{
    color: #fff;
}
.bell_icon_new span{
    background-color: #fff;
    color: #000;
    border-radius: 50%;
    padding: 2px 7px 2px 6px;
    
}