.TargetFormGroup{
    margin-bottom: 10px;
}
.TargetFormGroup:last-child{
    margin-bottom: 0;
}
.TargetFormGroup label{
    font-size: 13px;
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}
.TargetFormGroup input,.TargetFormGroup select,.TargetFormGroup textarea{
    font-size: 13px;
    width: 100%;
    border: 1px solid #000;
    padding: 7px 10px;
    border-radius: 5px;
}
.TargetFormGroup input::placeholder{
    color: #000;
}
.TargetFormGroup  button{
    font-size: 13px;
    border: none;
    background-color: rgb(8, 160, 187);
    padding: 10px;
    color: #fff;
    border-radius: 5px;
    width: 125px;
    letter-spacing: 2px;
    font-weight: 500;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.TabsPannel{
    margin-top: 20px;
}
.TabsPannel > div{
    padding: 0;
}
.GraphModal{
    display: none;
}
.GraphModal.active{
    display: block;
}
.TargetDetails{
    padding-top: 20px;
}
.TargetDetails h4{
    font-size: 13px;
    margin-bottom: 10px;
}
.TargetComplete.red{
    color: red;
}
.TargetComplete.blue{
    color: #05a5e5;
}
.TargetComplete.green{
    color: green;
}
.TargetPending{
    color: red;
}