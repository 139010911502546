.InputContainer{
    display: flex;
    /* gap: 10px; */
    align-items: center;
    justify-content: start;
}
.InputContainer input{
    width: 100%;
    padding: 10px;
    font-size: 13px;
    color: #000;
    border:1px solid #6f6c6c;
    /* border-radius: 10px; */
    outline: none;
}
.AddCardBtn{
    padding: 10px;
    background-color: rgb(9, 183, 206);
    border: 1px solid rgb(9, 183, 206);
    color: #fff;
    font-size: 13px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.EditTitleBtn{
    width: 35px;
    height: 35px;
    padding: 0;
}
.EditTitleBtn svg{
    font-size: 13px;
}
.CardsConatiner{
    margin-top: 20px;
    background-color: #f5f5f5;
    padding: 20px;
}
.CardsInputContainer{
    display: flex;
    align-items: center;
    justify-content: start;
    background-color: #fff;
    padding-left:  10px;
    margin-top: 10px;
}
.CardsInputContainer input{
    border: none;
    background-color: transparent;
    font-weight: 600;
    outline: none;
    width: 100%;
}
.CardsContainer{
    background-color: #f5f5f5;
    margin-top: 10px;
}
.css-1glm63j-MuiTypography-root.AccordianTitle{
    font-size: 13px;
    color: #000;
    font-weight: 600;
    text-transform: uppercase;
    display: flex;
    align-items: center;
}
.MuiAccordion-heading .css-eqpfi5-MuiAccordionSummary-content.Mui-expanded{
    margin: 0px;
}
div:has(> .DashDeatilContain){
    padding-top: 0;
}
.DashDeatilContain{
    border-top: 1px solid #6f6c6c;
    padding-top: 10px;
}
.DashDeatilContain .formGroup label{
    display: block;
    color: #000;
    font-weight: 500;
    margin-bottom: 5px;
}
.DashDeatilContain .formGroup textarea{
    width: 100%;
    border: 1px solid rgb(160, 159, 159);
    border-radius: 5px;
    outline: none;
    padding: 10px;
}
.DashImgAccordian{
    width: auto;
    max-width: 100%;
    margin: auto;
    
}
.Containmember{
    display: flex;
    align-items: center;
    justify-content: start;
    position: relative;
    margin-top: 5px;
}
.DashBtn{
    border: none;
    color: #fff;
    padding: 7px 15px;
    background-color: rgb(10, 185, 216);
    border-radius: 5px;
}
.DashBtn.save{
    background-color: rgb(10, 185, 216);
}
.DashBtn.cancel{
    background-color: red;
}
.membersList{
    background-color: #fff;
    color: #000;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    position: absolute;
    right: 0;
    bottom: 33px;
    z-index: 101;
    width: 190px;
}
.membersList h5{
    font-size: 14px;
    background-color: gray;
    color: #fff;
    padding: 10px;
    padding-right: 25px;
    text-align: center;
    margin-bottom: 0;
    position: relative;
}
.CloseBtnMember{
    background: transparent;
    border: none;
    font-size: 13px;
    position: absolute;
    top: 7px;
    right: 5px;
    color: #fff;
}
.membersList ul{
    margin: 0;
    padding: 10px;
}
.membersList li{
    list-style: none;
    padding: 5px;
    text-align: start;
    transition: .4s;
    border-bottom: 1px solid #e7e7e7;
    font-weight: 500;
}
.membersList li:hover{
    background-color: #e7e7e7;
}
.ShowMembersContaon{
    border: 1px solid gray;
    margin: 10px 0;

}
.ShowMembersContaon h5{
    background-color: gray;
    color: #fff;
    padding: 10px;
    text-align: center;
    margin-bottom: 5px;
}
.ShowMembersContaon ul{
    padding: 0;
    margin: 0;
    list-style: none;
    padding: 10px;
}
.ShowMembersContaon ul li {
    margin-bottom: 5px;
}
.m-0 .MuiAccordionSummary-content{
    margin: 0;
}