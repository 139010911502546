

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.poppins-thin {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: normal;
  }
 
* {
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    box-sizing: border-box;
    font-size: 14px;
    scrollbar-width: thin;
    font-family: "Roboto", sans-serif;

}
div a{
    text-decoration: none;
    color: #000;
}
p{
    margin: 0;
}
  
  p {
    margin-top: 10px;
    font-size: 18px;
    color: #3498db; /* Match the spinner color */
  }

/* Content container start */
.ContentContainer{
    background-color: #fff;
    padding: 15px 20px;
    border-radius: 20px;
    margin-top: -20px;
    margin-bottom: 57px;
    overflow: auto;
    max-height: calc(100vh - 132px);
}
/* .SubContain{
    padding: 0 20px;
    overflow: auto;
    height: 79vh;
    
} */

/* slider style start */

.SlideContain .carousel-item,.SlideContain .swiper-slide {
    position: relative;
    max-height: 170px;
    border-radius: 10px;
}

.SlideContain .carousel-item img,.SlideContain .swiper-slide img {
    width: 100%;
    /* height: 150px; */
    object-fit: cover;
    border-radius: 10px;
}
.SlideContain .carousel-control-next{
    visibility: hidden;
    opacity: 0;
}


/* slider style end */

/* Content card start */
.ContentCard{
    background-color: #fffefe;
    padding: 15px 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-top: 20px;
    border-radius: 10px;
}
.Content_head{
    display: flex;
    justify-content: space-between;
    align-items: start;
}
.Content_right{
    width: 80%;
}
.Content_right{
    width: 20%;
    display: flex;
    justify-content: right;
    align-items: center;
}
.Content_right img{
    width: 40px;
}
.Contain{
    /* display: flex;
    justify-content: space-between;
    align-items: center; */
    padding: 21px 0 10px;
}
.Title{
    text-transform: uppercase;
    font-weight: 500;
}
.Table{
    border: none;
    width: 100%;
}
.Table tr th,.Table tr td{
padding: 5px 0 0;
}
.Table tr th p{
    font-weight: 400;
    margin: 0;
}
.ContentCard p{
    font-size: 12px;
    color: #000;
    margin-bottom: 5px;
    letter-spacing: 1px;
    line-height: 1.5;
    font-family: "Poppins", sans-serif;
}
.Bright{
    font-size: 12px;
    font-weight: bold;
    color: #000;
    margin-left: 3px;
    text-transform: uppercase;
}
.ContentCard p.PBold{
    font-weight: bold;
    margin: 0;
}
.Word_breack{
    white-space: pre-wrap;      /* CSS3 */   
    white-space: -moz-pre-wrap; /* Firefox */    
    white-space: -pre-wrap;     /* Opera <7 */   
    white-space: -o-pre-wrap;   /* Opera 7 */    
    word-wrap: break-word;      /* IE */
}
/* Content card end */

/* Icons card start */
.BtnContain{
    padding: 10px;
    margin-top: 10px;
    /* display: flex;
    gap: 20px;
    flex-wrap: wrap; */
}
.IconBtn{
    width: 100%;
    max-width: 110px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #fffefe;
    color: #000;
    text-align: center;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.IconBtn img{
    width: 25px;
}
.IconBtn span{
    display: block;
    text-align: center;
    margin-top: 10px;
    font-size: 10px;
    font-weight: 400;
}

/* Icons card end */

