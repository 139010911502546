.BotHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px;
  padding-bottom: 40px;
  background-color: orangered;
  border-bottom: 1px solid #ccc;
}

.BotIcon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin: 0 5px;
}

.BotProfilePic {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.BotName {
  flex-grow: 1;
  text-align: center;
  font-weight: bold;
}

.ContentContainer.BotChatApp {
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: calc(100vh - 131px);
  width: 100%;
  padding: 10px;
  overflow: hidden;
  background-color: #f9f9f9;
  margin-bottom: 0;
}

.BotChatWindow {
  flex: 1;
  padding: 10px;
  padding-bottom: 20px;
  overflow-y: auto;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  justify-content: flex-start
}

.BotMessageInput {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  border-top: 1px solid #ccc;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 57px;
  width: 100%;
  margin-bottom: 10px;
}

.BotInput {
  flex: 1;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid #ccc;
  margin: 0 10px
}

.BotFileInput {
  display: none;
}

.BotUploadLabel {
  cursor: pointer;
}

.BotSendButton {
  background: none;
  border: none;
  cursor: pointer;
  background-color: green;
  padding: 8px 6px 7px 10px;
  border-radius: 50%;

}

.BotSendIcon {
  width: 24px;
  height: 24px;
}

.BotUserMessage {
  align-self: flex-end;
  /* background-color: #d1e7dd; */
  background-color: #c7cecb3d;
  border-radius: 10px;
  padding: 10px;
  margin: 5px 0;
  max-width: 70%;
  position: relative;
  min-width: 150px;
}

.BotUserMessage::before {
  content: '';
  position: absolute;
  bottom: -1px;
  right: -7px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 12px 15px 12px;
  border-color: transparent transparent #edeeee transparent;
  transform: rotate(37deg);
  -webkit-transform: rotate(25deg);
  -ms-transform: rotate(37deg);
  border-radius: 10px;

}

.BotTimestamp {
  font-size: 11px;
}

.BotCheckIcons svg {
  width: 16px;
}

.BotMessage::before {
  content: '';
  position: absolute;
  bottom: -1px;
  left: -7px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 12px 15px 12px;
  border-color: transparent transparent #fce9ea transparent;
  transform: rotate(37deg);
  -webkit-transform: rotate(-12deg);
  -ms-transform: rotate(37deg);
  border-radius: 10px;

}

.BotMessage {
  align-self: flex-start;
  background-color: #fce9ea;
  border-radius: 10px;
  padding: 10px;
  margin: 5px 0;
  max-width: 70%;
  position: relative;
}



.BotMessageText {
  margin-bottom: 5px;
}

.BotImageMessage {
  max-width: 100%;
  border-radius: 10px;
  margin-bottom: 5px;
}

.BotMessageFooter {
  /* display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 12px;
    color: #888;
    gap: 3px;
    width: 100%; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #888;
  /* gap: 3px; */
  width: 100%;
  margin-top: 8px;
}

.BotMessageFooter svg {
  width: 16px;
  margin-top: 2px;
}

.BotTimestamp {
  margin-top: 6px;
  margin-right: 5px;
  /* width: 100%; */
}

.BotCheckIcons {
  display: flex;
  align-items: center;
  color: green;
}

.BotCheckIcon {
  color: #4caf50;
  font-size: 16px;
}

.custom-anchor {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
}

button.disabled-btn {
  display: none;
}

/* .download-btn a{
    float: right;
    border: none;
    background-color: transparent;
    margin-left: 10px;
  } */

.download-btn img {
  float: right;
}

/* ----------- */
/* General Modal Styling */
.chat_modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Ensure modal stays on top */
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0s 0.3s;
  /* Hide initially */
}

.chat_modal.open {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease, visibility 0s 0s;
  /* Show modal with animation */
}

.chat_modal_overlay {
  /* position: absolute;
  top: 0;
  left: 0; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); */
  background-color: rgba(0, 0, 0, 0.9);
}

.chat_modal_content {
  position: relative;
  background-color: white;
  width: 80%;
  max-width: 500px;
  border-radius: 8px;
  /* padding: 20px; */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.chat_modal_header {
  font-size: 1.25rem;
  font-weight: bold;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
  padding: 10px 15px 5px 15px;
  display: flex;
  justify-content: space-between;
  background-color: orangered;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  color: white;
}

.chat_modal_body {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  overflow: hidden;
}

.chat_modal_body img {
  max-width: 100%;
  max-height: 400px;
  border-radius: 8px;
  object-fit: cover;
}

.chat_modal_body video {
  max-width: 100%;
  max-height: 400px;
  border-radius: 8px;
  object-fit: cover;
}

.chat_modal_body iframe {
  max-width: 100%;
  max-height: 400px;
  border-radius: 8px;
  object-fit: cover;
}

.chat_modal_body embed {
  max-width: 100%;
  max-height: 400px;
  border-radius: 8px;
  object-fit: cover;
}


.chat_modal_body p {
  font-size: 1rem;
  color: #333;
  margin-top: 10px;
}

.chat_modal_footer {
  float: right;
  /* margin-top: 20px; */
  padding: 0px 15px 10px 15px;
}

.chat_modal_footer button {
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cancel-btn {
  background-color: #f44336;
  color: white;
  border: none;
}

.cancel-btn:hover {
  background-color: #d32f2f;
}

.send-btn {
  background-color: #3f51b5;
  color: white;
  border: none;
}

.send-btn:hover {
  background-color: #303f9f;
}

.send-btn.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.send-btn:active {
  background-color: #1a237e;
}

.preview-container img,
.preview-container video {
  height: 150px;
}

.BotUserMessage:last-child,
.BotMessage:last-child {
  margin-bottom: 50px;
}

.date-header {
  text-align: center;
  background-color: lightgray;
  width: fit-content;
  margin: 15px auto;
  padding: 4px 15px;
  border-radius: 5px;

}

.date-header:first-child {
  margin: 0 auto;
}

.date-header span {
  font-size: 14px;
  font-weight: 500;
}

.download-btn p {
  word-break: break-all;
  font-size: 12px;
  max-width: 120px;
  margin-bottom: 0px;
}

/* .preview-container.preview-container-pdf{
  margin: auto;
  display: flex;
  justify-content: center;
} */
.preview-container.medio_icon {
  margin: auto;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.preview-container.preview-container-pdf img {
  width: 50px;
  height: 50px;
}

.preview-container-image img {
  height: 50px;
  width: 50px;
  /* padding: 10px 14px 10px 14px; */

}

.preview-container-video img {
  height: 50px;
  width: 50px;
  /* padding: 10px 14px 10px 14px; */

}

.chat_modal_footer2 {
  display: flex;
  justify-content: end;
}


.chat_modal_footer2 button {
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}


.chat_modal_overlay2 {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  /* background-color: rgba(0, 0, 0, 0.5); */
  background-color: rgba(0, 0, 0, 0.9);

}

.chat_modal_content2 {
  position: relative;
  background-color: white;
  width: 80%;
  max-width: 500px;
  border-radius: 8px;
  /* padding: 20px; */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  z-index: 1;
  /* border: 1px solid black; */
}

.chat_modal_header2 {
  font-size: 1.25rem;
  font-weight: bold;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
  padding: 10px 15px 5px 15px;
  display: flex;
  justify-content: space-between;
  background-color: orangered;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  color: white;

}


.chat_modal_body2 {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  overflow: hidden;
}

.chat_modal_body2 img {
  max-width: 100%;
  max-height: 400px;
  border-radius: 8px;
  object-fit: cover;
}

.chat_modal_body2 video {
  max-width: 100%;
  max-height: 400px;
  border-radius: 8px;
  object-fit: cover;
}

.chat_modal_body2 iframe {
  max-width: 100%;
  max-height: 400px;
  border-radius: 8px;
  object-fit: cover;
}

.chat_modal_body2 embed {
  max-width: 100%;
  max-height: 400px;
  border-radius: 8px;
  object-fit: cover;
}

.chat_modal_body2 object {
  max-width: 100%;
  max-height: 400px;
  border-radius: 8px;
  object-fit: cover;
}

.download-btn img {
  width: 23px;
    cursor: pointer;
    border: 1px solid #3498db;
    border-radius: 50%;
    padding: 4px;
    position: absolute;
    right: 13px;
    bottom: 8px;
}

.download-btn {
  display: flex;
  align-items: center;
  gap: 20px;
  background-color: #f9f5f5;
  width: 100%;
  padding: 5px;
  border-top: 1px solid #f5e6e6;
  padding-top: 10px;

}

.media-item {
  background-color: #f9f5f5;
  border-radius: 5px;
}

.preview-container {
  padding-bottom: 10px;
}

.modal_content_new {
  background-color: #fff;
  width: 80%;
  height: 300px;
  position: relative;
  border-radius: 10px;
  /* padding-top: 40px; */
}

.modal_content_new img {
  max-width: 150px;
  max-height: 150px;
  margin: auto;
  display: flex;
margin-top: 15px;
}
.modal_content_new img.close_btn_icon{
  position: absolute;
  right: 4px;
  top: -5px;
  width: 27px;
}.chat_header_title{
  font-size: 24px;
  color: #000;
  text-align: left;
  padding-top: 15px;
  padding-left: 24px;
}.modal_content_new p{
  text-align: left;
  margin-bottom: 0;
  color: #000;
  padding-top: 4px;
  padding-left: 25px;
}

.preview-container.preview-container-pdf{
  min-width: 132px;
}
