.pdfIcon{
    border: none;
    background: transparent;
}
.pdfIcon img{
    width: 20px;
}
.bggreen{
    background-color: rgb(228 252 228);
    color: green;
}
.bggreen td{
    color: green;
}
.bgred{
    background-color: rgb(252 243 243);
    color: red;
}
.bgred td{
    color: red;
}
.TrackDetails.RequestDetails .CardCount.colorRed,.colorRed{
    color: orangered;
    font-weight: 500;
}
.TrackDetails.RequestDetails .CardCount.ColorGreen,.ColorGreen{
    color: green;
    font-weight: 500;
}
.TrackDetails.RequestDetails .CardCount.Colorblue,.Colorblue{
    color: #0993bd;
    font-weight: 500;
}