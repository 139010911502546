.PendingHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f5f5f5;
    padding: 10px;
}
.PendingHeader h4{
    font-size: 13px;
    text-wrap: nowrap;
    margin-bottom: 0;
}
.PendingHeader .PendingDropDown select{
    padding: 5px;
    border: 1px solid #666565;
    border-radius: 5px;
    min-width: 100px;
    max-width: 150px;
    width: 100%;
    font-size: 13px;
    outline: none;
}
.PendingIssueStatus{
    position: relative;
}
.PendingIssueStatus h4{
    padding-right: 55px;
}
.TrackMessageCard.PendingIssueStatus .CardCount{
    position: absolute;
    right: -5px;
    top: -8px;
}
.rate_btn_box.rate_btn_box2{
    align-items: center;
}
.showing_rate{
    margin-top: -7px;
    margin-left: 5px;
}
.CustomizeLists{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Devider{
    border-bottom: 1px solid #eeecec;
    margin: 5px 0;
}
.PendingHeader.pending_box_n{
    flex-direction: column;
    position: relative;
}
.pending_filter{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
 
}
.search_input_pending{
    width: 100%;
    padding: 8px 30px 8px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 12px;
    margin-bottom: 10px;
}
.search-icon.search_icon_n{
    top: 28px;
    left: initial;
    right: 15px;

}